import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { Navigate } from "react-router-dom";
import moment from "moment";
import SweetAlert from 'sweetalert2';
import { 
	AUTH_PREFIX_PATH, 
	UNAUTHENTICATED_ENTRY, 
	REDIRECT_URL_KEY 
} from 'configs/AppConfig'
import downloadjs from "downloadjs";
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
const htmlToImage = require("html-to-image");

// export const encodeParams = auth => {
//   const encryptedText = CryptoJS.AES.encrypt(JSON.stringify(auth), process.env.REACT_APP_SECRET_KEY).toString();
//   const shortText = encryptedText.substring(0, 10);
//   localStorage.setItem(shortText, encryptedText);
//   return shortText;
// };

// export const decodeParams = shortText => {
//   const encryptedText = localStorage.getItem(shortText);
//   if (!encryptedText) {
//        return <Navigate to="/stock-management/member-outstanding/" replace /> 
//   }
//   const decryptedBytes = CryptoJS.AES.decrypt(encryptedText, process.env.REACT_APP_SECRET_KEY);
//   const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
//   return JSON.parse(decryptedText);
// };

export const encodeAuth = auth => {
    const deText = CryptoJS.AES.encrypt(JSON.stringify(auth), process.env.REACT_APP_SECRET_KEY).toString();
    return deText;
};

export const decodeAuth = auth => {
    try{
        const bytes  = CryptoJS.AES.decrypt(auth, process.env.REACT_APP_SECRET_KEY);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    }
    catch(err){
        const getFromStore = Cookies.get("vb\C#^*gW589");
        if(getFromStore){
            Cookies.remove("vb\C#^*gW589")
            return  <Navigate to="/signin" replace />
        }else{
            return  <Navigate to="/signin" replace />
        };
    }
};

export const LogoutHandle = (navigate) => {
    let getRole = localStorage.getItem("last_login_role");
    Cookies.remove("vb\C#^*gW589");
    if(getRole === 'admin'){
      navigate(`${AUTH_PREFIX_PATH}/admin${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}`);
    }else{
      navigate(`${AUTH_PREFIX_PATH}/agent${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}`);
    }
    window.location.reload();
};

export const replaceZeotoQqual = (str) => {
    if(str){
      if (str.includes("+")) {
        let splitVal = str.split("+");
  
        if (splitVal[0] === "0" && splitVal[1] === "0") {
          splitVal[0] = "0";
          splitVal[1] = "=";
        } else {
          if (splitVal[0] === "0") {
            splitVal[0] = "=";
          } else {
            splitVal[0] = `${splitVal[0]}`;
          }
          if (splitVal[1] === "0") {
            splitVal[1] = "=";
          } else {
            splitVal[1] = `+${splitVal[1]}`;
          };
        };
        return splitVal;
      }
      if (str.includes("=")) {
        let splitVal = str.split("=");
        if (splitVal[0] === "0") {
          splitVal[0] = "";
        } else {
          splitVal[0] = `${splitVal[0]}=`;
        }
        if (splitVal[1] === "0") {
          splitVal[1] = "";
        } else {
          splitVal[1] = `${splitVal[1]}`;
        }
        return splitVal;
      }
      
      if (str.includes("-")) {
        let splitVal = str.split("-");
        if (splitVal[0] === "0" && splitVal[1] === "0") {
          splitVal[0] = "0";
          splitVal[1] = "=";
        } else {
          if (splitVal[0] === "0") {
            splitVal[0] = "=";
          } else {
            splitVal[0] = `${splitVal[0]}`;
          }
          if (splitVal[1] === "0") {
            splitVal[1] = "=";
          } else {
            splitVal[1] = `-${splitVal[1]}`;
          }
        };
        return splitVal;
      }
    
      switch (str) {
        case "0":
          return "D";
        case "1":
          return "1=";
        case "2":
          return "2=";
        case "3":
          return "3=";
        case "4":
          return "4=";
        case "5":
          return "5=";
        default:
          return str;
      }
    };
};

export const generatePassword = () => {
  const minChars = 8;
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const numbers = "0123456789";
  let generatedPassword = '';
  generatedPassword += numbers.charAt(Math.floor(Math.random() * numbers.length));
  for (let i = 1; i < minChars; i++) {
    generatedPassword += charset.charAt(Math.floor(Math.random() * charset.length));
  };
  generatedPassword = generatedPassword.split('').sort(() => 0.5 - Math.random()).join('');

  return generatedPassword;
};

export const showNumber = (num,def='-') => {
  if(num){
    return Number(num)?.toLocaleString("en-US")
  }
  else{
    return def
  }
};

export const opposite = (value) => {
  return value > 0 ? value * -1 : Math.abs(value);
};

export const popupCenter = ({ url, title, w, h }) => {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `
  );

  if (window.focus) newWindow?.focus();
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getTodayDate = (startDate,endDate) => {
  let current_hour = moment().format("HH:mm");
  let start_date = moment(startDate)
    .startOf("day")
    .add({ hours: 10, minutes: 29 })
    .format("YYYY-MM-DD HH:mm:ss");
  let end_date = moment(endDate)
    .startOf("day")
    .add({ days: 1, hours: 10, minutes: 29 })
    .format("YYYY-MM-DD HH:mm:ss");
  if (current_hour < "10:29") {
    start_date = moment(startDate)
      .startOf("day")
      .subtract({ days: 1 })
      .add({ hours: 10, minutes: 29 })
      .format("YYYY-MM-DD HH:mm:ss");
    end_date = moment(endDate)
      .startOf("day")
      .add({ hours: 10, minutes: 29 })
      .format("YYYY-MM-DD HH:mm:ss");
  };
  return { start_date, end_date };
};

export const renameMarketNameToShow = (market) => {
  if (market == "total") {
    market = "o/u";
  }
  if(market=='one_x_two'){
    market='1x2'
  }
  if(market=='odd_even'){
    market='odd/even'
  }
  if(market=='correct_score'){
    market='correct score'
  }
  return market;
};

export const getLocalAuthUser = ()=>{
  const getAuth = Cookies.get("vb\C#^*gW589");
  let userAuth = getAuth && decodeAuth(getAuth);
  return userAuth;
}

export async function copyToClipboard(copyMe) {
  await navigator.clipboard.writeText(copyMe);
  SweetAlert.fire({
    icon: "success",
    width: 300,
    title: "Success",
    text: "Copy to Clipboard",
  });
}

export function splitOdds(odds) {
  return odds.split(/([+\-=])/);
};

export const htmlToPng = async (saveDom, name) => {
  if (saveDom) {
    htmlToImage.toPng(saveDom).then(function (dataUrl) {
      downloadjs(dataUrl, `${name}.png`);
    });
  }
};

export const capitalizedText = (text) => {
  if (text) {
    let words = text.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }

    let capitalized = words.join(" ");
    return capitalized;
  }
};

export const exportExcelFormat = (exalData, fileName) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx"
  const ws = XLSX.utils.json_to_sheet(exalData);
  const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
  const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
  const data = new Blob([excelBuffer], {type: fileType});
  FileSaver.saveAs(data, fileName + fileExtension)
};


export const balanceService = (mainBalance,memberBalance,outstandingBalance) => {
  let bal = {
      cash_balance: 0,
      member_balance:0,
      account_balance: 0,
      outstanding_balance: 0
  };

  bal.cash_balance = Number(mainBalance || 0);
  bal.member_balance = Number(memberBalance || 0) + Number(outstandingBalance || 0);
  bal.account_balance = Number(mainBalance || 0) + Number(memberBalance || 0) + Number(outstandingBalance || 0);
  bal.outstanding_balance = Number(outstandingBalance || 0);
  return bal;
};


export const clearLocalStorageAndCookies = () => {
  // LocalStorage ဖျက်
  localStorage.removeItem('w-l-t');
  localStorage.removeItem('s-w-l-t');
  Cookies.remove('vb\C#^*gW589');

  // Cookies ဖျက်
  // const cookies = document.cookie.split(";");
  // cookies.forEach(cookie => {
  //     const eqPos = cookie.indexOf("=");
  //     const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
  //     document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  // });
}