import React, { useEffect, useRef, useState } from 'react';
import { Routes as RouterRoutes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig';
import { protectedRoutes, publicRoutes } from 'configs/RoutesConfig';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import AppRoute from './AppRoute';
import ClearStorage from 'auth/ClearStorage';
import { publicGetMethodService } from 'services';
import { SETTING_JSON } from 'services/apis';
import { useDispatch } from 'react-redux';
import { setsettings } from 'store/slices/settingsSlice';
import Maintenance from 'utils/Maintenance';
import Cookies from 'js-cookie';

const Routes = () => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [intervalStatus,setIntervalStatus] = useState(true);
	const intervalRef = useRef(null);

	const fetchSettingJson = async () => {
		const res = await publicGetMethodService(SETTING_JSON);
        if(res){
            let obj = {
                status: res?.is_active_dashboard,
                remark: res?.disable_reason,
                announancement: null,
            };
			setIntervalStatus(obj?.status);
            dispatch(setsettings(obj));
            if(!obj?.status){
				Cookies.remove("vb\C#^*gW589");
                return navigate("/maintenance");
            };
        };
    };

	useEffect(()=>{
        if(!intervalStatus){
            intervalRef.current = setInterval(() => {
                fetchSettingJson();
            }, 1000 * 10);
        }else{
            clearInterval(intervalRef.current);
        };
    },[intervalStatus]);


	useEffect(() => {
		window.scrollTo(0, 0);
		fetchSettingJson();
	}, [pathname]);
	return (
		<RouterRoutes>
			<Route path="/maintenance" element={<Maintenance />} />
			<Route path="/" element={<ProtectedRoute />}>
				<Route path="/" element={<Navigate replace to={AUTHENTICATED_ENTRY} />} />
				{protectedRoutes.map((route, index) => {
					return (
						<Route 
							key={route.key + index} 
							path={route.path}
							element={
								<AppRoute
									routeKey={route.key} 
									component={route.component}
									{...route.meta} 
								/>
							}
						/>
					)
				})}
				<Route path="/clear" element={<ClearStorage />} />
				<Route path="*" element={<Navigate to="/" replace />} />
			</Route>
			<Route path="/" element={<PublicRoute />}>
				{publicRoutes.map(route => {
					return (
						<Route 
							key={route.path} 
							path={route.path}
							element={
								<AppRoute
									routeKey={route.key} 
									component={route.component}
									{...route.meta} 
								/>
							}
						/ >
					)
				})}
			</Route>
		</RouterRoutes>
	)
}

export default Routes