import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig';
import { decodeAuth } from 'utils/Helper';
import Cookies from 'js-cookie';


const getLocalAuth = Cookies.get("vb\C#^*gW589");
const dcode = getLocalAuth ? decodeAuth(getLocalAuth): null;

export const publicRoutes = [
    {
        key: 'admin-login',
        path: `${AUTH_PREFIX_PATH}/admin/login`,
        component: React.lazy(() => import('views/auth-views/authentication/admin-login')),
    },
    {
        key: 'agent-login',
        path: `${AUTH_PREFIX_PATH}/agent/login`,
        component: React.lazy(() => import('views/auth-views/authentication/agent-login')),
    },
    {
        key: 'change-password',
        path: `${AUTH_PREFIX_PATH}/change-password`,
        component: React.lazy(() => import('views/auth-views/authentication/change-password')),
    }
];

let role = dcode?.user?.role_name;
let usersRoute = [];

const isAdmin = (route) => {
    if(dcode?.user?.role_name === "admin" && dcode?.user?.type === "main"){
        return route;
    }else{
        return {};
    };
};

const isNotAdmin = (navRoute) => {
    if(dcode?.user?.role_name !== "admin"){
        return navRoute;
    }else{
        return {};
    };
};

 const isAgent = (navRoute) => {
    if(dcode?.user?.role_name === "agent"){
        return navRoute;
    }else{
        return {};
    };
  };
  
const isSubAdmin = (route,permit) => {
    if(dcode?.user?.role_name === "admin" && dcode?.user?.type === "sub"){
        if(dcode?.user?.permission === permit){
            return route;
        };
        return {};
    }else{
        return {};
    };
};
  
const isSubAccount = (navRoute,showAgent) => {
    if(dcode?.user?.role_name === "admin"){
        if(dcode?.user?.type === "sub"){
          return {};
        }else{
          return navRoute;
        }
    }else{
      if(dcode?.user?.type === "main"){
        return navRoute;
      }else{
        if(showAgent){
          return navRoute;
        }else{
          return {};
        };
      }
    };
  };

switch(role){
    case "admin":
      usersRoute = [
        {
            key: 'member-super',
            path: `${APP_PREFIX_PATH}/member/supers`,
            component: React.lazy(() => import('views/app-views/member/Super')),
        }
      ];
   break;
   case "super":
     usersRoute = [
        {
            key: 'member-senior',
            path: `${APP_PREFIX_PATH}/member/seniors`,
            component: React.lazy(() => import('views/app-views/member/Senior')),
        }
     ]
   break;
   case "senior":
     usersRoute = [
        {
            key: 'member-master',
            path: `${APP_PREFIX_PATH}/member/masters`,
            component: React.lazy(() => import('views/app-views/member/Master')),
        },
        {
            key: 'member-agent',
            path: `${APP_PREFIX_PATH}/member/agents`,
            component: React.lazy(() => import('views/app-views/member/Agent')),
        },
        {
            key: 'member-user',
            path: `${APP_PREFIX_PATH}/member/users`,
            component: React.lazy(() => import('views/app-views/member/User')),
        },
     ]
   break;
   case "master":
    usersRoute = [
        {
            key: 'member-agent',
            path: `${APP_PREFIX_PATH}/member/agents`,
            component: React.lazy(() => import('views/app-views/member/Agent')),
        },
        {
            key: 'member-user',
            path: `${APP_PREFIX_PATH}/member/users`,
            component: React.lazy(() => import('views/app-views/member/User')),
        },
     ]
   break;
   case "agent":
    usersRoute = [
        {
            key: 'member-user',
            path: `${APP_PREFIX_PATH}/member/users`,
            component: React.lazy(() => import('views/app-views/member/User')),
        },
     ]
   break;
   default: 
 };

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'change-password',
        path: `${APP_PREFIX_PATH}/account-setting/change-password`,
        component: React.lazy(() => import('views/app-views/change-password')),
    },
    isNotAdmin({
        key: 'profile',
        path: `${APP_PREFIX_PATH}/account-setting/profile`,
        component: React.lazy(() => import('views/app-views/profile')),
    }),
    isSubAccount({
        key: 'body-ou-full-time',
        path: `${APP_PREFIX_PATH}/stock-management/full-time`,
        component: React.lazy(() => import('views/app-views/member-management/FullTime')),
    },true),
    isSubAccount({
        key: 'body-ou-half-time',
        path: `${APP_PREFIX_PATH}/stock-management/half-time`,
        component: React.lazy(() => import('views/app-views/member-management/HalfTime')),
    },true),
    isSubAccount({
        key: 'even-odd',
        path: `${APP_PREFIX_PATH}/stock-management/even-odd`,
        component: React.lazy(() => import('views/app-views/member-management/EvenOdd')),
    },true),
    // isSubAccount({
    //     key: 'one-x-two',
    //     path: `${APP_PREFIX_PATH}/stock-management/one-x-two`,
    //     component: React.lazy(() => import('views/app-views/member-management/OnexTwo')),
    // },true),
    // isSubAccount({
    //     key: 'correct-scores',
    //     path: `${APP_PREFIX_PATH}/stock-management/correct-scores`,
    //     component: React.lazy(() => import('views/app-views/member-management/CorrectScores')),
    // },true),
    isSubAccount({
        key: 'member-outstanding',
        path: `${APP_PREFIX_PATH}/stock-management/member-outstanding`,
        component: React.lazy(() => import('views/app-views/member-management/MemberOustanding')),
    },true),

    isSubAdmin({
        key: 'body-ou-full-time',
        path: `${APP_PREFIX_PATH}/stock-management/full-time`,
        component: React.lazy(() => import('views/app-views/member-management/FullTime')),
    },"access_reporting"),
    isSubAdmin({
        key: 'body-ou-half-time',
        path: `${APP_PREFIX_PATH}/stock-management/half-time`,
        component: React.lazy(() => import('views/app-views/member-management/HalfTime')),
    },"access_reporting"),
    isSubAdmin({
        key: 'even-odd',
        path: `${APP_PREFIX_PATH}/stock-management/even-odd`,
        component: React.lazy(() => import('views/app-views/member-management/EvenOdd')),
    },"access_reporting"),
    isSubAdmin({
        key: 'one-x-two',
        path: `${APP_PREFIX_PATH}/stock-management/one-x-two`,
        component: React.lazy(() => import('views/app-views/member-management/OnexTwo')),
    },"access_reporting"),
    isSubAdmin({
        key: 'correct-scores',
        path: `${APP_PREFIX_PATH}/stock-management/correct-scores`,
        component: React.lazy(() => import('views/app-views/member-management/CorrectScores')),
    },"access_reporting"),
    isSubAdmin({
        key: 'member-outstanding',
        path: `${APP_PREFIX_PATH}/stock-management/member-outstanding`,
        component: React.lazy(() => import('views/app-views/member-management/MemberOustanding')),
    },"access_reporting"),
    {
        key: 'stock-detail',
        path: `${APP_PREFIX_PATH}/stock-management/detail/:matchType/:oddsType/:id`,
        component: React.lazy(() => import('views/app-views/member-management/StockDetail')),
    },
    
    {
        key: 'member-outstanding-detail',
        path: `${APP_PREFIX_PATH}/stock-management/member-outstanding/:usercode/:id`,
        component: React.lazy(() => import('views/app-views/member-management/MemberOustanding')),
    },
    isSubAccount({
        key: 'report-win-lose',
        path: `${APP_PREFIX_PATH}/report/win-lose-report`,
        component: React.lazy(() => import('views/app-views/report/WinLose')),
    },true),
    isSubAccount({
        key: 'report-win-lose-slot',
        path: `${APP_PREFIX_PATH}/report/win-lose-report-slot`,
        component: React.lazy(() => import('views/app-views/report/WinLoseSlot')),
    },true),
    isSubAccount({
        key: 'report-win-lose-slot-v2',
        path: `${APP_PREFIX_PATH}/report/win-lose-report-slot/v2`,
        component: React.lazy(() => import('views/app-views/report/WinLoseSlotSummary')),
    },true),
    isSubAccount({
        key: 'transaction-history',
        path: `${APP_PREFIX_PATH}/report/transaction-history`,
        component: React.lazy(() => import('views/app-views/report/TransactionHistory')),
    },true),
    isSubAdmin({
        key: 'report-win-lose',
        path: `${APP_PREFIX_PATH}/report/win-lose-report`,
        component: React.lazy(() => import('views/app-views/report/WinLose')),
    },"access_reporting"),
    isSubAdmin({
        key: 'report-win-lose-slot',
        path: `${APP_PREFIX_PATH}/report/win-lose-report-slot`,
        component: React.lazy(() => import('views/app-views/report/WinLoseSlot')),
    },"access_reporting"),
    isSubAdmin({
        key: 'report-win-lose-slot-v2',
        path: `${APP_PREFIX_PATH}/report/win-lose-report-slot/v2`,
        component: React.lazy(() => import('views/app-views/report/WinLoseSlotSummary')),
    },"access_reporting"),
    isSubAdmin({
        key: 'transaction-history',
        path: `${APP_PREFIX_PATH}/report/transaction-history`,
        component: React.lazy(() => import('views/app-views/report/TransactionHistory')),
    },"access_reporting"),
    isSubAccount({
        key: 'report-win-lose-report',
        path: `${APP_PREFIX_PATH}/report/win-lose-report/:usercode/:id/:start_date/:end_date`,
        component: React.lazy(() => import('views/app-views/report/WinLose')),
    },true),
    isSubAccount({
        key: 'report-win-lose-slot',
        path: `${APP_PREFIX_PATH}/report/win-lose-report-slot/:usercode/:id/:start_date/:end_date`,
        component: React.lazy(() => import('views/app-views/report/WinLoseSlot')),
    },true),
    isSubAccount({
        key: 'report-win-lose-slot',
        path: `${APP_PREFIX_PATH}/report/win-lose-report-slot/v2/:usercode/:id/:start_date/:end_date`,
        component: React.lazy(() => import('views/app-views/report/WinLoseSlotSummary')),
    },true),
    isSubAdmin({
        key: 'report-win-lose-report',
        path: `${APP_PREFIX_PATH}/report/win-lose-report/:usercode/:id/:start_date/:end_date`,
        component: React.lazy(() => import('views/app-views/report/WinLose')),
    },"access_reporting"),
    isSubAdmin({
        key: 'report-win-lose-slot',
        path: `${APP_PREFIX_PATH}/report/win-lose-report-slot/:usercode/:id/:start_date/:end_date`,
        component: React.lazy(() => import('views/app-views/report/WinLoseSlot')),
    },"access_reporting"),
    isSubAdmin({
        key: 'report-win-lose-slot-v2',
        path: `${APP_PREFIX_PATH}/report/win-lose-report-slot/v2/:usercode/:id/:start_date/:end_date`,
        component: React.lazy(() => import('views/app-views/report/WinLoseSlotSummary')),
    },"access_reporting"),
    
    isAdmin({
        key: 'create-odds',
        path: `${APP_PREFIX_PATH}/football/create-odds`,
        component: React.lazy(() => import('views/app-views/football/CreateOdds')),
    }),
    isAdmin({
        key: 'created-odds',
        path: `${APP_PREFIX_PATH}/football/created-odds`,
        component: React.lazy(() => import('views/app-views/football/CreatedOdds')),
    }),
    isAdmin({
        key: 'create-result',
        path: `${APP_PREFIX_PATH}/football/create-result`,
        component: React.lazy(() => import('views/app-views/football/CreateResult')),
    }),
    isAdmin({
        key: 'teams',
        path: `${APP_PREFIX_PATH}/football/teams`,
        component: React.lazy(() => import('views/app-views/football/Teams')),
    }),
    isAdmin({
        key: 'setting',
        path: `${APP_PREFIX_PATH}/football/setting`,
        component: React.lazy(() => import('views/app-views/football/Settings')),
    }),

    isSubAdmin({
        key: 'create-odds',
        path: `${APP_PREFIX_PATH}/football/create-odds`,
        component: React.lazy(() => import('views/app-views/football/CreateOdds')),
    },"manage_matches"),
    isSubAdmin({
        key: 'created-odds',
        path: `${APP_PREFIX_PATH}/football/created-odds`,
        component: React.lazy(() => import('views/app-views/football/CreatedOdds')),
    },"manage_matches"),
    isSubAdmin({
        key: 'create-result',
        path: `${APP_PREFIX_PATH}/football/create-result`,
        component: React.lazy(() => import('views/app-views/football/CreateResult')),
    },"confirm_scores"),
    isSubAccount({
        key: 'bet-list-single',
        path: `${APP_PREFIX_PATH}/bet-list/single`,
        component: React.lazy(() => import('views/app-views/bet-list/BetListSingle')),
    },true),
    isSubAccount({
        key: 'bet-list-parlay',
        path: `${APP_PREFIX_PATH}/bet-list/parlay`,
        component: React.lazy(() => import('views/app-views/bet-list/BetListParlay')),
    },true),
    isSubAccount({
        key: 'bet-list-all',
        path: `${APP_PREFIX_PATH}/bet-list/users/:id/:start_date/:end_date`,
        component: React.lazy(() => import('views/app-views/bet-list/BetListAll')),
    },true),
    isSubAccount({
        key: 'slot-bet-list-all',
        path: `${APP_PREFIX_PATH}/slot-bet-list/users/:id/:start_date/:end_date`,
        component: React.lazy(() => import('views/app-views/slot-betting/BetList')),
    },true),
    isSubAccount({
        key: 'bet-list-parlay-detail',
        path: `${APP_PREFIX_PATH}/bet-list/detail/:id`,
        component: React.lazy(() => import('views/app-views/bet-list/BetListDetail')),
    },true),
    isSubAdmin({
        key: 'bet-list-single',
        path: `${APP_PREFIX_PATH}/bet-list/single`,
        component: React.lazy(() => import('views/app-views/bet-list/BetListSingle')),
    },"access_reporting"),
    isSubAdmin({
        key: 'bet-list-parlay',
        path: `${APP_PREFIX_PATH}/bet-list/parlay`,
        component: React.lazy(() => import('views/app-views/bet-list/BetListParlay')),
    },"access_reporting"),
    isSubAdmin({
        key: 'bet-list-all',
        path: `${APP_PREFIX_PATH}/bet-list/users/:id/:start_date/:end_date`,
        component: React.lazy(() => import('views/app-views/bet-list/BetListAll')),
    },"access_reporting"),
    isSubAdmin({
        key: 'slot-bet-list-all',
        path: `${APP_PREFIX_PATH}/slot-bet-list/users/:id/:start_date/:end_date`,
        component: React.lazy(() => import('views/app-views/slot-betting/BetList')),
    },"access_reporting"),

    isSubAdmin({
        key: 'bet-list-parlay-detail',
        path: `${APP_PREFIX_PATH}/bet-list/detail/:id`,
        component: React.lazy(() => import('views/app-views/bet-list/BetListDetail')),
    },"access_reporting"),
    ...usersRoute,
    isSubAccount({
        key: 'member-sub-account',
        path: `${APP_PREFIX_PATH}/member/sub-account`,
        component: React.lazy(() => import('views/app-views/member/SubAccount')),
    },false),
    isSubAccount({
        key: 'create-sub-account',
        path: `${APP_PREFIX_PATH}/member/create-sub-account`,
        component: React.lazy(() => import('views/app-views/member/CreateSubAccount')),
    },false),
    isSubAccount({
        key: 'create-user',
        path: `${APP_PREFIX_PATH}/member/:role/create`,
        component: React.lazy(() => import('views/app-views/member/CreateUser')),
    },false),
    isSubAccount({
        key: 'edit-user',
        path: `${APP_PREFIX_PATH}/member/:role/edit/:id`,
        component: React.lazy(() => import('views/app-views/member/EditUser')),
    },false),
    isSubAccount({
        key: 'deposit-withdrawl',
        path: `${APP_PREFIX_PATH}/payment/deposit-withdrawl`,
        component: React.lazy(() => import('views/app-views/payment/DepositWithdrawl')),
    },false),
    isSubAccount({
        key: 'upline',
        path: `${APP_PREFIX_PATH}/payment/upline`,
        component: React.lazy(() => import('views/app-views/payment/Upline')),
    },false),
    {
        key: 'old-create-user',
        path: `${APP_PREFIX_PATH}/member/old-version/:role/create`,
        component: React.lazy(() => import('views/app-views/member/old-version/CreateUser')),
    },
    {
        key: 'old-edit-user',
        path: `${APP_PREFIX_PATH}/member/old-version/:role/edit/:id`,
        component: React.lazy(() => import('views/app-views/member/old-version/EditUser')),
    },
    isAgent(
        {
            key: 'wallet-transaction',
            path: `${APP_PREFIX_PATH}/user-transactions/wallet`,
            component: React.lazy(() => import('views/app-views/transaction/Wallet')),
        }
    ),
    {
        key: 'check-slip-id',
        path: `${APP_PREFIX_PATH}/user-transactions/betting`,
        component: React.lazy(() => import('views/app-views/transaction/Betting')),
    },

    isAdmin({
        key: 'app-setting',
        path: `${APP_PREFIX_PATH}/app-setting`,
        component: React.lazy(() => import('views/app-views/setting/AppSetting')),
    }),
]