let main_domain = process.env.REACT_APP_MAIN_DOMAIN;
let soccer_domain = process.env.REACT_APP_SOCCER_DOMAIN;

export const ADMIN_LOGIN_API = `${main_domain}api/main/auth/admin-login`;
export const ADMIN_LOGOUT_API = `${main_domain}api/main/auth/logout`;
export const AGENT_LOGIN_API = `${main_domain}api/main/auth/login/agent`;
export const CHANGE_PASSWORD_API = `${main_domain}api/main/users/change-password`;
export const USERS_BALANCES_API = `${main_domain}api/main/users/dashboard/total-balance`;
export const USERS_COUNT_API = `${main_domain}api/main/users/dashboard/total-user`;
export const DOWNLINE_PASSWORD_CHANGE_API = id => `${main_domain}api/main/users/change-password/${id}`;
export const USER_DETAIL_API = id => `${main_domain}api/main/users/${id}`;
export const SUB_USERS_API = `${main_domain}api/main/users/sub-accounts`;
export const TEX_SETTING_UPDATE_API = id => `${main_domain}api/soccer/bettings/tax-percent/${id}`;
export const SETTINGS_API = `${main_domain}api/main/config?is_cache=false`;
export const EDIT_SETTINGS_API = `${main_domain}api/main/config/update`;


export const SLOT_BALANCE_API = `https://syscasino.com/api/games/slot/max-accept-limit`;



// Slot API
export const SLOT_BETTING_API = `${main_domain}api/games/slot/slot-bettings`;
export const GAME_BALANCE_API = `${main_domain}api/games/slot/game-balance`;







export const FIXTURES_API = `${soccer_domain}api/soccer/fixtures/third-party/upcomming-events`;
export const SEARCH_FIXTURES_API = `${soccer_domain}api/soccer/fixtures/third-party/search-events`;
export const FIXTURE_API =  `${soccer_domain}api/soccer/fixtures/`;
export const FIXTURES_UPDATE_API = id =>  `${soccer_domain}api/soccer/fixtures/${id}`;
export const FIXTURES_OPEN_ALL_API = `${soccer_domain}api/soccer/fixtures/all/change-is-published`
export const LEAGUES_API = `${soccer_domain}api/soccer/fixtures/leagues`;
export const ODDS_API = id => `${soccer_domain}api/soccer/fixtures/third-party/odds/${id}`;
export const LIVE_GOALS_API = id => `${soccer_domain}api/soccer/fixtures/third-party/events/${id}`
export const RESULT_CONFIRM_API = id => `${soccer_domain}api/soccer/fixtures/confirm-score/${id}`
export const RESULT_REJECT_API = id => `${soccer_domain}api/soccer/fixtures/reject-score/${id}`;
export const REJECT_SLIP_API = id => `${soccer_domain}api/soccer/bettings/reject/${id}`
export const TEAMS_API = `${soccer_domain}api/soccer/fixtures/names`;
export const EDIT_TEAM_API = id => `${soccer_domain}api/soccer/fixtures/names/${id}`;



export const ROLLBACK_API = id => `${soccer_domain}api/soccer/fixtures/rollback-score/${id}`;

export const TRANSLATE_API = `${soccer_domain}api/soccer/fixtures/translate/`;
export const MEMBER_OUTSTANDING_API = `${soccer_domain}api/soccer/bettings/member-outstanding`;



// user management
export const USER_CREATE_API = `${main_domain}api/main/users`;
export const USER_EDIT_CONFIG_API = id => `${main_domain}api/main/users/config/${id}`;
export const USER_EDIT_COMMISSION_API = id => `${main_domain}api/main/users/commission/${id}`;
export const PAYMENT_SUBMIT_API = `${main_domain}api/main/wallets/payments`


export const TEAM_IMAGE = (data) => {
  let encodedString = data?.code;
    // const encodedString = encodeURIComponent(data?.code);
    return `${soccer_domain}api/soccer/fixtures/images/${encodedString}/teams/${data?.name_en}`;
  };


export const SETTING_JSON = `https://sportsxzone.s3.ap-southeast-1.amazonaws.com/control.json`;
