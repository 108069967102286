import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    settingsAgent: null,
};
export const settingAgentSlice = createSlice({
    name: 'settingsAgent',
    initialState,
    reducers: {
        setsettingsAgent: (state, action) => {
            state.settingsAgent = action.payload
        },
    },
});

export const { setsettingsAgent } = settingAgentSlice.actions
export default settingAgentSlice.reducer