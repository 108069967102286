import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { clearLocalStorageAndCookies } from 'utils/Helper';

const ClearStorage = () => {
    const navigate = useNavigate();

    useEffect(() => {
      clearLocalStorageAndCookies();
      navigate('/', { replace: true });
    }, [navigate]);
  
    return null; 
}

export default ClearStorage
