import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import refresh from './slices/refreshSlice'
import userInfo from './slices/userInfoSlice'
import setting from './slices/settingsSlice'
import settingAgent from './slices/settingAgentSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        refresh,
        userInfo,
        setting,
        settingAgent,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
