import Cookies from "js-cookie";
import { decodeAuth } from '../utils/Helper';
import axios from 'axios';

export const publicGetMethodService = async (api) => {
    try {
        let fetcher = await fetch(api ,{
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
        });
        let res = await fetcher.json();
        if(fetcher.status === 200){
            return res;
        }else{
            return false;
        };
    } 
    catch (error) {
        if(error?.message === "Failed to fetch"){
            console.log(error);
        }
    }
}

export const getMethodService = async (api) => {
    const getAuth = Cookies.get('vb\C#^*gW589');
    let userAuth = getAuth && decodeAuth(getAuth);

    try{
        let fetcher = await fetch(api ,{
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `${userAuth?.token}`,
            },
        });
        let res = await fetcher.json();
        if(fetcher.status === 500){
            return res?.message || res?.errors || "Something Wrong!";
        };
        if(fetcher.status === 429){
            return res?.message || res?.errors || "Something Wrong!";
        };
        if(fetcher.status === 400){
            return res?.message || res?.errors || "Something Wrong!";
        };
        if(fetcher.status === 401){
            return "Unauthenticated";
        };
        if(fetcher.status === 404){
            return res?.message || res?.errors || "Something Wrong!";
        };
        if(fetcher.status === 200){
            if(res){
                if(res.status === "success" || res.status || res.success){
                    return res;
                }
            };
        };
    }
    catch(err){
        return err.message;
    }
    
};

export const postMethodService = async (api,data) => {
    const getAuth = Cookies.get('vb\C#^*gW589');
    let userAuth = getAuth && decodeAuth(getAuth);
    try{
        let fetcher = await fetch(api ,{
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `${userAuth?.token}`,
            },
            body: JSON.stringify(data),
        });
        let res = await fetcher.json();
        if(fetcher.status === 500){
            return res?.message || res?.errors || "Something Wrong!";
        };
        if(fetcher.status === 429){
            return res?.message || res?.errors || "Something Wrong!";
        };
        if(fetcher.status === 400){
            return res?.message || res?.errors || "Something Wrong!";
        };
        if(fetcher.status === 401){
            return "Unauthenticated";
        };
        if(fetcher.status === 404){
            return res?.message || res?.errors || "Something Wrong!";
        };
        if(fetcher.status === 200){
            if(res){
                if(res.status === "success" || res.status || res.success){
                    return res;
                }
            };
        };
    }
    catch(err){
        return err.message;
    }
};

export const putMethodService = async (api,data) => {
    const getAuth = Cookies.get('vb\C#^*gW589');
    let userAuth = getAuth && decodeAuth(getAuth);
    try{
        let fetcher = await fetch(api ,{
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `${userAuth?.token}`,
            },
            body: JSON.stringify(data),
        });
        let res = await fetcher.json();
        if(fetcher.status === 500){
            return res?.message || res?.errors || "Something Wrong!";
        };
        if(fetcher.status === 429){
            return res?.message || res?.errors || "Something Wrong!";
        };
        if(fetcher.status === 400){
            return res?.message || res?.errors || "Something Wrong!";
        };
        if(fetcher.status === 401){
            return "Unauthenticated";
        };
        if(fetcher.status === 404){
            return res?.message || res?.errors || "Something Wrong!";
        };
        if(fetcher.status === 200){
            if(res){
                if(res.status === "success" || res.status || res.success){
                    return res;
                }
            };
        };
    }
    catch(err){
        return err.message;
    };
};